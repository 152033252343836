import React from "react";

const Eye = () => (
  <svg width="73" height="85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.932 27.441c8.53 0 15.501 6.81 15.501 15.143 0 8.333-6.97 15.142-15.501 15.142-8.532 0-15.502-6.809-15.502-15.142 0-8.334 6.97-15.143 15.502-15.143Zm0 26.931C43.59 54.372 49 49.088 49 42.584c0-6.505-5.41-11.79-12.068-11.79-6.659 0-12.069 5.286-12.069 11.79s5.41 11.788 12.069 11.788Z"
      fill="currentColor"
    />
    <path
      d="M.624 40.956c.624-.813 16.75-19.106 36.101-19.106 19.352 0 35.166 18.191 35.894 19.004l.208.508v1.931l-.208.508c-.624.813-16.542 19.106-35.894 19.106-19.35 0-35.58-18.191-36.205-19.004L0 43.395v-1.931l.624-.508ZM36.83 59.452c15.605 0 29.442-13.72 32.668-17.175-3.226-3.354-17.063-16.972-32.668-16.972-15.606 0-29.443 13.72-32.668 17.073 3.225 3.456 17.062 17.074 32.668 17.074ZM34.75 0h3.432v15.142H34.75V0ZM34.75 68.7h3.432v16.16H34.75V68.7ZM.014 72.784l11.034-10.78 2.428 2.371-11.034 10.78-2.428-2.37ZM58.805 19.148 70.28 7.938l2.428 2.372-11.477 11.21-2.427-2.372ZM.097 10.303 2.524 7.93 14 19.141l-2.427 2.372L.097 10.303ZM59.3 64.403l2.427-2.37L72.762 72.81l-2.428 2.372L59.3 64.403Z"
      fill="currentColor"
    />
    <path
      d="M29.13 39.126c.624 1.22 1.873 2.033 3.433 2.033 2.081 0 3.85-1.626 3.85-3.76 0-1.118-.52-2.135-1.353-2.846.52-.101.937-.101 1.457-.101 4.473 0 8.01 3.556 8.01 7.825 0 4.37-3.64 7.825-8.01 7.825-4.37 0-8.011-3.557-8.011-7.825-.104-1.017.104-2.134.624-3.15Z"
      fill="currentColor"
    />
  </svg>
);

export default Eye;
